export const environment = {
    production: true,
    elemental: false,
    deploymentName: 'Cat Daddies',
    companyName: 'Cat Daddies Cores',
    website: 'catdaddiestbd.com',
    converterImageLogo: '/assets/images/catdaddies/logo.png',
    converterImageLogoSmall: '/assets/images/catdaddies/logo_small.png',
    converterWatermarkText: 'CAT DADDIES CORES',
    dashboardLogoLight: '/assets/images/catdaddies/logo_horizontal.png',
    dashboardLogoDark: '/assets/images/catdaddies/logo_horizontal.png',
    dashboardLogoAspectRatio: 2.5,
    welcomeLogo: '/assets/images/catdaddies/logo_horizontal_dark.png',
    welcomePageUrl: 'https://catdaddiescores.com',
    hasWelcomePageLinks: false,
    defaultApiEndpoint: 2,
    logLevel: 2,
    versionCheckUrl: null,
    azureMapsKey: 'UgrphyDHUyrpcxDu4HeJi9Zm1f05rXHV7ZsP0-fys0w'
};
